import React, { useContext, useEffect, useState } from 'react';
import { Link, graphql } from 'gatsby';
import { MDXRenderer } from 'gatsby-plugin-mdx';

import Bio from '../components/bio';
import Layout from '../components/layout';
import SEO from '../components/seo';
import { rhythm, scale } from '../utils/typography';
import { DiscussionEmbed } from 'disqus-react';
import { ThemeManagerContext } from 'gatsby-styled-components-dark-mode';
import Image from 'gatsby-image';
import styled from 'styled-components';

const BlogPostTemplate = ({ pageContext, data, location }) => {
  const post = data.mdx;
  const siteTitle = data.site.siteMetadata.title;
  const { slug, previous, next } = pageContext;
  const themeContext = useContext(ThemeManagerContext);
  const [disqusDarkTheme, setDisqusDarkTheme] = useState(!themeContext.isDark);

  useEffect(() => {
    setTimeout(() => setDisqusDarkTheme(themeContext.isDark), 300);
  }, [themeContext.isDark]);

  const disqusConfig = {
    shortname: pageContext.env.GATSBY_DISQUS_NAME,
    config: { identifier: slug, title: siteTitle },
  };

  return (
    <Layout location={location} title={siteTitle}>
      <SEO
        title={post.frontmatter.title}
        description={post.frontmatter.description || post.excerpt}
      />

      {post.frontmatter.cover_image && (
        <Image
          fixed={post.frontmatter.cover_image.childImageSharp.fixed}
          alt={post.frontmatter.title}
          style={{
            maxWidth: '100%',
            maxHeight: rhythm(12),
            marginTop: rhythm(1),
          }}
        />
      )}

      <h1>{post.frontmatter.title}</h1>
      <Container>
        <Image
          fixed={data.avatar.childImageSharp.fixed}
          alt={data.site.siteMetadata.author}
          style={{
            marginRight: rhythm(1 / 2),
            marginBottom: 0,
            minWidth: 50,
            borderRadius: `100%`,
          }}
          imgStyle={{
            borderRadius: `50%`,
          }}
        />
        <p>
          {post.frontmatter.date}
          <br />
          by <strong>{data.site.siteMetadata.author}</strong>
        </p>
      </Container>

      <MDXRenderer>{post.body}</MDXRenderer>
      <hr
        style={{
          marginBottom: rhythm(1),
        }}
      />
      {/* <Bio /> */}
      <ul
        style={{
          display: `flex`,
          flexWrap: `wrap`,
          justifyContent: `space-between`,
          listStyle: `none`,
          padding: 0,
        }}
      >
        <li>
          {previous && (
            <Link to={`/blog${previous.fields.slug}`} rel="prev">
              ← {previous.frontmatter.title}
            </Link>
          )}
        </li>
        <li>
          {next && (
            <Link to={`/blog${next.fields.slug}`} rel="next">
              {next.frontmatter.title} →
            </Link>
          )}
        </li>
      </ul>
      <hr
        style={{
          marginBottom: rhythm(1),
        }}
      />
      <DiscussionEmbed
        {...disqusConfig}
        theme={disqusDarkTheme ? 'dark' : 'light'}
      />
    </Layout>
  );
};

export default BlogPostTemplate;

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    mdx(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      body
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
        cover_image {
          publicURL
          childImageSharp {
            # sizes(maxWidth: 1240) {
            #   srcSet
            # }
            fixed(width: 1000) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    }
    avatar: file(absolutePath: { regex: "/chris-kalmar.png/" }) {
      childImageSharp {
        fixed(width: 50, height: 50) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`;

const Container = styled.div`
  display: flex;
  margin-bottom: 40px;
  font-size: 14px;
`;
